<template>
  <div class="home">
  <HomepageHero />
  <HomepageFeatures />
  <HomepageSoon />
  </div>
</template>

<script>
import HomepageHero from "@/components/HomeComponents/HomeHero.vue"
import HomepageFeatures from "@/components/HomeComponents/HomeFeatures.vue"
import HomepageSoon from "@/components/HomeComponents/HomeSoon.vue"
export default {
  name: 'HomeView',
  components: {
    HomepageHero,
    HomepageFeatures,
    HomepageSoon
  }
  ,mounted () {
  window.scrollTo(0, 0)
}
}
</script>
