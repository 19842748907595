<template>
  <div id="app">
  <HeaderComponent />
    <router-view  style="padding-top:4rem;" />
  <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue"
import FooterComponent from "./components/FooterComponent.vue"
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>


<style lang="scss">
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}
</style>
