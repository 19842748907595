<template>
    <div id="privacy">
    <div class="privacy-wrapper mx-1200 pb-5">
        <title>About the Privacy Policy for Runity App</title>

<h1>About the Privacy Policy for Runity App</h1>
<p><strong>Effective Date:</strong> 2023-08-30</p>

<p>Welcome to Runity! This Privacy Policy is designed to explain how we collect, use, share, and protect your personal information when you use our Runity app. By using our app, you consent to the practices outlined in this Privacy Policy.</p>

<h2>Information We Collect</h2>
<p>When you interact with our Runity app, we may collect various types of information, including:</p>

<ol>
    <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and contact details when you register as a user on our app. This information is used to provide a personalized experience and facilitate communication.</li>
    <li><strong>Location Information:</strong> With your explicit consent, we collect location data while you use the run tracking feature within the app. This data helps provide accurate tracking of your running activities.</li>
    <li><strong>Purchase Information:</strong> If you purchase running events or related services through the app, we collect necessary transaction details to facilitate purchases and event participation.</li>
    <li><strong>Device and Usage Information:</strong> We gather information about your device, including its model, operating system version, unique identifiers, and how you interact with the app. This information is used to optimize app performance and enhance user experience.</li>
</ol>

<h2>How We Use Your Information</h2>
<p>We use the collected information for various purposes, including:</p>

<ol>
    <li><strong>Run Tracking:</strong> Location information is used to provide accurate run tracking features, allowing you to monitor your running activities effectively.</li>
    <li><strong>Personalization:</strong> We use the collected data to customize the app's features, content, and recommendations based on your preferences and usage patterns.</li>
    <li><strong>Communication:</strong> Personal information enables us to respond to your inquiries, offer support, and keep you informed about events or updates related to the app.</li>
    <li><strong>Purchase Processing:</strong> Purchase information is used to process transactions, confirm event registrations, and provide you with relevant event details.</li>
</ol>

<h2>Data Sharing and Disclosure</h2>
<p>We may share your personal information under certain circumstances:</p>

<ol>
    <li><strong>Event Organizers:</strong> When you register for running events, we may share relevant information with event organizers to ensure smooth event participation.</li>
    <li><strong>Service Providers:</strong> Third-party service providers assist us in app operations, payment processing, and analytics. These providers are bound by confidentiality agreements.</li>
    <li><strong>Legal Obligations:</strong> We may disclose information to comply with legal requirements, enforce our terms of use, protect our rights, or respond to legal requests.</li>
</ol>

<h2>Your Choices</h2>
<p>You have certain rights regarding your personal information:</p>

<ol>
    <li><strong>Location Sharing:</strong> You can enable or disable location sharing for run tracking within the app settings.</li>
    <li><strong>Account Deletion:</strong> You can request account deletion, and we will promptly remove your personal information from our systems. To initiate the account deletion process, please contact us at <a href="mailto:support@runity.com.my">support@runity.com.my</a>.</li>
</ol>

<h2>Data Security</h2>
<p>We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p>

<h2>Changes to This Privacy Policy</h2>
<p>We may modify this Privacy Policy to reflect changes in our practices or due to legal or operational reasons. Significant changes will be communicated through the app or our website.</p>

<h2>Contact Us</h2>
<p>For questions about this Privacy Policy or to request account deletion, please contact us at <a href="mailto:support@runity.com.my">support@runity.com.my</a>.</p>

<p>By using the Runity app, you acknowledge your understanding of this Privacy Policy and consent to its terms.</p>

<p><strong>LEE SPORTWEAR INTERNATIONAL SDN. BHD.</strong><br>
12, Jalan Vervea 3, Vervea <br>
14110 Simpang Ampat Pulau Pinang<br>
Contact Email: <a href="mailto:support@runity.com.my">support@runity.com.my</a><br>
Website: https://runity.com.my</p>

<p><em>Note: This template provides a general structure for a privacy policy, incorporating account deletion. Legal consultation is recommended to ensure compliance with relevant laws and regulations.</em></p>

    </div>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
  mounted () {
  window.scrollTo(0, 0)
}
}
</script>

<style lang="scss" scoped>
    @import "@/assets/css/reset.css";
    @import "@/assets/css/all.scss";

.privacy-wrapper {
    h1 {
        padding-top: 3rem;
        font-weight: 700;
        font-size: 24px;
    }
    h2 {
        padding-top: 1.5rem;
        font-size:20px;
        font-weight:600;
    }
ul{
    list-style: disc;
}
}
</style>